  <div class="card-body" style="margin-top: -8px;">
    <h5 class="card-title text-center" style="font-size: 13pt;">Bitte einen Stand auswählen</h5>
    <mat-selection-list 
      id="container" 
      [multiple]="false" 
      #availableStand 
      [(ngModel)]="selectedStand">
        <mat-list-option 
          *ngFor="let stand of standList;"
          [value]="stand"
          class="list-line text-center11"
          color="primary"
          >
          <span style="color: black; font-size: 14px;" [class.color_list_option]="availableStand.selectedOptions.selected[0]?.value==stand">{{stand.Nummer}} {{stand.Typ}}</span>
        </mat-list-option>
    </mat-selection-list>
    
    <button 
      class="btn btn_next float-end btn-primary"
      (click)="goToTableSelect(availableStand.selectedOptions.selected[0]?.value.Nummer,
              availableStand.selectedOptions.selected[0]?.value.Typ)"
      [disabled]="availableStand.selectedOptions.selected.length === 0">
      weiter
    </button>
  </div>