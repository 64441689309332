<mat-toolbar style="background-color: #006ab2;" *ngIf="isLoggedIn">
  <img src="assets/images/logo_white.png">
  <span class="toolbar-spacer"></span>
  <button class="btn btn_logout" (click)="logout()">
    Abmelden
  </button>
</mat-toolbar>
<!-- fxLayoutAlign="center center" -->
<!-- class="col-sm-6 offset-md-3" -->
<div>
  <router-outlet></router-outlet>
</div>