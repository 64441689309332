import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// -------------
import { Observable } from 'rxjs';
import { Stand } from '../_models/stand';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StandsService {

  standurl = environment.api_endpoint;

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient) {
   }



  async getCategoriesOrg() {
    try {
      const data: any = await this.http.get(this.standurl + '/client/service/categories/', {headers: this.headers}).toPromise();
      return data;
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  }

  getCategories(): Observable<Stand[]> {
    return this.http.get<Stand[]>(this.standurl + '/client/service/categories/', {headers: this.headers})
      .pipe(
        map(
          (response: Stand[]) => {
            return response;
          }
        )
      );
  }

}
