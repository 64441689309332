<mat-toolbar style="background-color: #006ab2;">
    <img src="assets/images/logo_white.png">
</mat-toolbar>

<div class="card" style="width: 800px; margin: auto; margin-top: 100px;">
    <div class="card-header text-center1">
        <div class="row" style="margin: -10px -15px -10px -15px;">
            <!-- <img src="assets/images/fostat.gif" style="width: 180px; height: 90px;"> -->
            <!-- <img src="assets/images/fostat.gif" > -->
            <div class="" id="fostat"></div>
            <div class="title ">
                <h5>Daten zu </h5>
                <h5>Forschung und Innovation</h5>
                <h5>in Deutschland</h5>
            </div>
            <!-- <img src="assets/images/bmbf.png" style="height:100px; width: 200px;"> -->
            <!-- <img id="deneme"> -->
            <div class="" id="bmbf"></div>
        </div>
    </div>
    <div class="card-body" style="width: 400px;display: block;
        margin-right: auto; margin-left: auto;">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Benutzername</label>
                <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Geben Sie Ihr Benutzername ein.</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Passwort</label>
                <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Geben Sie Ihr Passwort ein.</div>
                </div>
            </div>
            <button [disabled]="loading" class="btn btn-primary btn_style">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Anmelden
            </button>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        </form>
    </div>
    <div class="card-footer text-muted text-center" style="font-size: 11pt;">
        <img class="sizefavicon" src="favicon.ico">
        Deutsches Zentrum für Hochschul- und Wissenschaftsforschung GmbH, Hannover
      </div>
</div>