import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'dzhw-fostat-client';

  isLoggedIn: any;

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private renderer: Renderer2) {
      this.renderer.setStyle(document.body,'background', 'WhiteSmoke')
      // this.authenticationService.currentUser.subscribe((value: any) => {
      //   if(value) {
      //     this.isLoggedIn = true;
      //     console.log('APP-True: ' + this.isLoggedIn)
      //   } else {
      //     this.isLoggedIn = false;
      //     console.log('APP-False: ' + this.isLoggedIn)
      //   }
      // });
      this.authenticationService.currentUser.subscribe(is => this.isLoggedIn = is)
      console.log('APP: ' + this.isLoggedIn)
    }
    logout() {
      this.authenticationService.logout();
      this.router.navigate(['/login']);
  }
}
