import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = localStorage.getItem('Authorization');
    const isApiUrl = request.url.startsWith(environment.api_endpoint);
    if (user && isApiUrl) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${user}`
            }
        });
    }
    
    return next.handle(request);
    // return next.handle(request).pipe(
    //     map((event: HttpEvent<any>) => {
    //         if (event instanceof HttpResponse) {
    //             console.log('event--->>>', event);
    //         }
    //         return event;
    //     }));
  }
}
