import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Stand } from '../_models/stand';

@Injectable({
  providedIn: 'root'
})
export class ChildComponentService {
  
  stand: Stand;
  constructor() { }

  private _standSubject: BehaviorSubject<Stand> = new BehaviorSubject(null);
  public standSubject: Observable<Stand> = this._standSubject.asObservable();

  setStandData(standNumber: number, typ:string) {
    this.stand = new Stand(standNumber, typ);
    this._standSubject.next(this.stand);
  }
}
