import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FostatStartComponent } from './fostat-start/fostat-start.component';
import { LoginComponent } from './login/login.component';
// import { StandsComponent } from './stands/stands.component';
// import { TablesComponent } from './tables/tables.component';
// import { AuthGuard } from './_interceptor/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'fostat', component: FostatStartComponent},
  // { path: 'stand', component: StandsComponent, canActivate: [AuthGuard]},
  // { path: 'table', component: TablesComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
