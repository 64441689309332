<div class="card-group11" style="display: flex;">
  <div class="card11 col-7">
    <div class="card-body" style="margin-bottom: -16px;">
      <h5 class="card-title text-center" style="margin-bottom: -2.5px; font-size: 13pt;">Bitte eine Tabelle auswählen</h5>
      
      <span style="font-size: 10pt;" class="text-muted">
        Ausgewählter Stand: {{selectedStandNumber}} {{selectedStandTyp}}
      </span>
      <mat-selection-list #tables class="table-list" [multiple]="false" [(ngModel)]="dafaultSelectedItem"
      style="height: 42vh; overflow-y: auto;" (selectionChange)="onNgModelChangeTable($event)">
        <mat-list-option *ngFor="let table of tableList" [value]="table" class="table-list-item text-center" color="primary">
          <span style="color: black; font-size: 14px;" [class.color_list_option]="tables.selectedOptions.selected[0]?.value==table">{{table.Nummer}}</span>
        </mat-list-option>
      </mat-selection-list>
      <button class="btn btn_style btn-primary" style="width: 75px;" (click)="backToStandComponent()">zurück</button>
    </div>
  </div>
  <div class="card col-5">
    <div class="card-body">
      <h5 class="card-title text-center" style="font-size: 13pt;">Ausgabeformat</h5>
      <mat-radio-group class="radio-group"
        [(ngModel)]="selectedFileType" style="height: 40vh;">
        <mat-radio-button color="primary" *ngFor="let fileType of fileTypes"
          [value]="fileType">
          <span class="radio_text" style="font-size: 10pt;"> {{fileType}} </span>
        </mat-radio-button>
        <form style="margin-top: 5px;">
          <div>
            <input #fileupload type="file" name="image" (change)="onFileSelected($event)">
          </div>
          <div>
            
            <span *ngIf="fileInfo" style="margin-left: 100px;"> {{fileInfo}} </span>
          <ng-container *ngIf="uploadSuccess">
            <span style="font-weight: bold;">  Hochladen erfolgreich! </span>
          </ng-container>
          <button mat-icon-button [disabled]="!btnUploadDisabled" [ngClass]="{'color_matIcon': btnUploadDisabled == true}"
            class="btn float-end" (click)="uploadFile()" title="xsl Datei hochladen">
            <mat-icon>file_upload</mat-icon>
          </button>
          <button mat-icon-button *ngIf="selectedFileName" 
            class="btn float-start" style="color: red;" (click)="resetFileUploader()" title="Abbrechen">
            <mat-icon>delete</mat-icon>
          </button>
          </div>
        </form>
      </mat-radio-group>
    </div>
    <button class="btn  btn-block btn-primary" [disabled]="btnUploadDisabled || loading"
    (click)="createFile(selectedFileType)" 
    [ngClass]="{'btn_style': !btnUploadDisabled == true, 'btn_style_disable': btnUploadDisabled == true }">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    Tabelle erzeugen</button>
  </div>
</div>