import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Tabelle } from '../_models/tabelle';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

  standurl = environment.api_endpoint;
  headers = new HttpHeaders({
    'Accept': '*',
    'Content-Type': 'application/json',
});


  constructor(private http: HttpClient) { }

  async getAllTableOrg(id: number) {
    try {
      const data: any = await this.http.get(this.standurl + '/client/service/tablelist/' + id).toPromise();
      return data;
    } catch (error) {
      console.error(`Error occurred: ${error}`);
    }
  }

  getAllTable(id: number): Observable<Tabelle[]> {
    return this.http.get<Tabelle[]>(this.standurl + '/client/service/tablelist/' + id, {headers: this.headers})
      .pipe(
        map(
          (response: Tabelle[]) => {
            return response;
          }
        )
      );
  }

  getTransformFile(einheitnummer: number, standnumber: number, format: String, language: String, headers: HttpHeaders): Observable<any> {
    return this.http.get(this.standurl + '/client/service/transform/' + 
    einheitnummer +'/'+ standnumber+'/' + format + '/' + language , {
      headers,
      responseType: 'blob'
    });
  }

  getXmlFile(einheitnummer: number, standnumber: number, headers: HttpHeaders): Observable<any> {
    
    return this.http.get(this.standurl + '/client/service/transform_xml/' + 
    einheitnummer +'/'+ standnumber , {
      headers,
      responseType: 'blob',
    });
  }

  getXslFile(format: string, language: string, headers: HttpHeaders): Observable<HttpResponse<Blob>> {

    return this.http.get<Blob>(this.standurl + '/client/service/transform_xsl/' +
    format + '/' + language, {headers, observe: 'response', responseType: 'blob' as 'json' });
  }
  
  uploadFile(fileName: string, fileToUpload: File): Observable<any> {
   
    return this.http
      .post(this.standurl +'/client/service/uploadFile/' + fileName, fileToUpload, {
        observe: 'events',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
  }

}
