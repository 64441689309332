import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_models/user';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject2: BehaviorSubject<User>;
  public currentUser2: Observable<User>;
  private _currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public currentUser: Observable<any> = this._currentUserSubject.asObservable();
  url = environment.api_endpoint;

  constructor(private http: HttpClient) {
    // this.currentUserSubject2 = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('Authorization')));
    // this.currentUser2 = this.currentUserSubject2.asObservable();
}

  // public get currentUserValue(): any {
    // console.log('servic--> ' + this.currentUserSubject2.value)
    // return this.currentUserSubject2.value;
  // }
  
  loginStatus(status: boolean) {
    this._currentUserSubject.next(status);
  }

  login(username: string, password: string) {
    const data = { 'username': username, 'userpass': password };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post<any>(this.url + '/client/service/auth/login', data, config)
      .pipe(map((user:User) => {
        if (user) {
          localStorage.setItem('Authorization', JSON.stringify(user).substr(25).slice(0, -2));
          this.loginStatus(true);
          // const user2 = localStorage.getItem('Authorization');
          // this.currentUserSubject2.next(user);
        }
        return user;
      }
      ));
  }

  logout() {
    this._currentUserSubject.next(false);
    localStorage.removeItem('Authorization');
    localStorage.clear();
  }
  
}
