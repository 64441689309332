import { Component, Input, OnInit } from '@angular/core';
import { ShowContent } from '../_models/show-content.enum';

@Component({
  selector: 'app-fostat-start',
  templateUrl: './fostat-start.component.html',
  styleUrls: ['./fostat-start.component.css']
})
export class FostatStartComponent implements OnInit {

  showPageContent = ShowContent.STAND;

  constructor(
  ) { }


  ngOnInit(): void {
  }

  get showPage(): boolean {
    if (this.showPageContent === ShowContent.STAND) {
      return true;
    } else {
      return false;
    }
  }

  // get showState(): boolean {
  //   if (this.showContent === ShowContent.STAND) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } 

  // get showTable(): boolean {
  //   if (this.showContent === ShowContent.TABLE) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  receiveContent(page: ShowContent) {
    this.showPageContent = page;
  }

}
