<!-- <div fxLayoutAlign11="center11" class="deneme2"> -->
<div class="card fostat_center" >
  <div class="card-header">
    <div class="row" style="margin: -10px -15px -10px -15px;">
      <!-- <img src="assets/images/fostat.gif"> -->
      <div id="fostat"></div>
      <div class="title">
        <h5>Daten zu </h5>
        <h5>Forschung und Innovation</h5>
        <h5>in Deutschland</h5>
      </div>
      <!-- <img src="assets/images/bmbf1.png"> -->
      <div id="bmbf"></div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="showPage" [ngTemplateOutlet]="stand"></ng-container>
    <ng-container *ngIf="!showPage" [ngTemplateOutlet]="table"></ng-container>
    <ng-template #stand>
      <app-stands (nextPageContent)="receiveContent($event)"></app-stands>
    </ng-template>
    <ng-template #table>
      <app-tables (previousPageContent)="receiveContent($event)"></app-tables>
    </ng-template>
  </div>
  <div class="card-footer text-muted text-center" style="font-size: 11pt;">
    <img class="sizefavicon" src="favicon.ico">
    Deutsches Zentrum für Hochschul- und Wissenschaftsforschung GmbH, Hannover
  </div>
</div>
<!-- </div> -->