import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ShowContent } from '../_models/show-content.enum';
import { TablesService } from '../_services/tables.service';
import { ChildComponentService } from '../_services/child-component.service';
import { Tabelle } from '../_models/tabelle';
import { saveAs } from 'file-saver-es';
import { HttpHeaders } from '@angular/common/http';
import { MatSelectionList } from '@angular/material/list';
import { Stand } from '../_models/stand';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit {
  seasons: string[] = ['PDF', 'EXCEL', 'Summer', 'Autumn'];

  /**
   * @Output() ermöglicht Daten vom Child an die Parent-Komponente zu senden.
   * previousPageContent der Name des @Output()
   * EventEmitter die Daten vom Typ ShowContent
   */
  @Output() previousPageContent = new EventEmitter<ShowContent>();
  @ViewChild('fileupload', { static: false }) fileupload: ElementRef;
  @ViewChild('tables') tables: MatSelectionList;

  // default Dateityp
  selectedFileType: string = 'PDF';
  selectedStandNumber: number;
  selectedStandTyp: string;
  selectedLanguage: string;
  einheitNummer: number;
  tabellenNummer: string;

  tableList: Tabelle[] = [];
  
  dafaultSelectedItem: Tabelle[] = [];
  loading = false;
  fileToUpload: File = null;
  selectedFileName: string = '';
  fileInfo: string;
  uploadSuccess: boolean;

  constructor(
    private tableService: TablesService,
    private childComponentService: ChildComponentService,
  ) { }

  resetFileUploader() { 
    this.fileupload.nativeElement.value = null;
    this.fileInfo = null;
    this.selectedFileName = null;
  }

  // Liste der verfügbaren Dateitypen
  fileTypes: string[] = ['PDF', 'EXCEL', 'HTML', 'XML',
  'PDF-Stylesheet', 'EXCEL-Stylesheet', 'HTML-Stylesheet', 'XSL-FO'];

  ngOnInit(): void {
    this.childComponentService.standSubject.subscribe((value: Stand) => {
      this.selectedStandNumber = value.Nummer;
      this.selectedStandTyp = value.Typ;
      this.selectedLanguage = value.Typ.substr(value.Typ.length - 2).toLowerCase();
      if (this.selectedLanguage !== 'en') {
        this.selectedLanguage = 'de'
      }
    });
    this.tableService.getAllTable(this.selectedStandNumber).subscribe(data => {
      this.tableList = data;
      // const index_tablelist = this.tableList.findIndex(table_list => table_list.Einheit === this.tableList[0].Einheit);
      // this.tables._keyManager.setActiveItem(index_tablelist);
      // console.log("index:: " + index_tablelist)
      setTimeout(() => {
        // this.tables.focus();
        this.tables._items.get(0)._hostElement.focus();
      }, 20)
      this.dafaultSelectedItem = [this.tableList[0]];
      this.einheitNummer = this.tableList[0].Einheit;
      this.tabellenNummer = this.tableList[0].Nummer;
    });
  }

  /**
   * back to standsComponent
   */
  backToStandComponent() {
    this.previousPageContent.emit(ShowContent.STAND);
  }

  /**
   * Handle tabelleNummer and einheitNummer of the clicked Tableattribute.
   * @param event Table event
   */
  onNgModelChangeTable(event: any) {
    this.einheitNummer = event.options[0].value.Einheit;
    this.tabellenNummer = event.options[0].value.Nummer;
  }

  /**
   * Generated File in selected Filetype
   * @param format selected File type
   */
  createFile(format: string) {
    this.loading = true;
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', '*/*');
    headers = headers.append('Content-Type', 'application/octet-stream');

    switch (format) {
      case 'PDF':
        // generate PDF
        this.tableService.getTransformFile(this.einheitNummer, this.selectedStandNumber, format.toLowerCase(), this.selectedLanguage, headers)
          .subscribe((response: any) => {
          let blob:any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
          const url= window.URL.createObjectURL(blob);
          window.open(url);
          this.loading = false;
        },
            error => {
                this.loading = false;
            });
        break;
      case 'EXCEL':
        format = 'xlsx';
        // generate EXCEL
        this.tableService.getTransformFile(this.einheitNummer, this.selectedStandNumber, format, this.selectedLanguage, headers).subscribe(
          (response: any) => {
            const blob = new Blob([response], { type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const file = new File([blob], (this.selectedStandNumber +'-'+this.tabellenNummer) + '.xlsx', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(file);
            this.loading = false;
          },
          error => {
              this.loading = false;
          }
        );
        break;
      case 'XML':
        // generate XML
        this.tableService.getXmlFile(this.einheitNummer, this.selectedStandNumber,headers)
          .subscribe((response: any) => {
            let blob:any = new Blob([response], { type: 'text/xml; charset=utf-8' });
            const url= window.URL.createObjectURL(blob);
            window.open(url);
            this.loading = false;
        },
        error => {
            this.loading = false;
        });
        break;
      case 'HTML':
        // generate HTML
        this.tableService.getTransformFile(this.einheitNummer, this.selectedStandNumber, format.toLowerCase(), this.selectedLanguage, headers)
          .subscribe((response: any) => {
            let blob:any = new Blob([response], { type: 'text/html; charset=utf-8' });
            const url= window.URL.createObjectURL(blob);
            window.open(url);
            this.loading = false;
        },
        error => {
            this.loading = false;
        });
        break;
      case 'XSL-FO':
        format = 'xslt-result';
        // generate XSL-FO
        this.tableService.getTransformFile(this.einheitNummer, this.selectedStandNumber, format, this.selectedLanguage, headers)
          .subscribe((response: any) => {
            let blob:any = new Blob([response], { type: 'application/xml; charset=utf-8' });
            const url= window.URL.createObjectURL(blob);
            window.open(url);
            this.loading = false;
        },
        error => {
            this.loading = false;
        });
        break;
      case 'PDF-Stylesheet':
        format = 'pdf';
        // generate PDF-Stylesheet
        this.tableService.getXslFile(format, this.selectedLanguage, headers)
          .subscribe(response => {
            const contentDisposition = response.headers.get('Content-Disposition');
            saveAs(response.body, contentDisposition);
            this.loading = false;
        },
        error => {
            this.loading = false;
        });
        break;
        case 'EXCEL-Stylesheet':
        format = 'xlsx';
        // generate EXCEL-Staylesheet
        this.tableService.getXslFile(format, this.selectedLanguage, headers)
          .subscribe(response => {
            const contentDisposition = response.headers.get('Content-Disposition');
            saveAs(response.body, contentDisposition);
            this.loading = false;
        },
        error => {
            this.loading = false;
        });
        break;
        case 'HTML-Stylesheet':
        format = 'html';
        // generate HTML-Stylesheet
        this.tableService.getXslFile(format, this.selectedLanguage, headers)
          .subscribe(response => {
            const contentDisposition = response.headers.get('Content-Disposition');
            saveAs(response.body, contentDisposition);
            this.loading = false;
        },
        error => {
            this.loading = false;
        });
      default:
        break;
    }
  }

  /**
   * (change)-event of file input tag
   * @param event Input event
   */
  onFileSelected(event) {
    this.fileToUpload = event.target.files[0];
    this.uploadSuccess = false;
    // convert size in Bytes to kB
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB'];
      const factor = 1024;
      let index = 0;

      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }

      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    if (event.target.files.length === 1) {
      this.selectedFileName = this.fileToUpload.name.substring(this.fileToUpload.name.length - 7, 0)
      + this.fileToUpload.name.substring(this.fileToUpload.name.length - 4);
      this.fileInfo = `(${formatBytes(this.fileToUpload.size)})`;
    } else {
      this.selectedFileName = '';
      this.fileInfo = '';
    }
  }

  /**
   * calling tables-service. Upload of input-File
   */
  uploadFile() {    
    this.tableService.uploadFile(this.fileToUpload.name, this.fileToUpload).subscribe(data => {
        this.uploadSuccess = true;
        this.resetFileUploader();
      }, error => {
        console.log(error);
      });
  }

  /**
   * the selected file is checked as suitable or not for upload
   */
  get btnUploadDisabled(): boolean {
    switch(this.selectedFileName) {
      case 'xml_to_pdf.xsl':
      case 'xml_to_html.xsl':
      case 'xml_to_xlsx.xsl':
        return true;
      default:
        return false;
    }
  }
}
