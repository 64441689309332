import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren,
  AfterViewInit, QueryList, HostListener, ViewEncapsulation } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { Router } from '@angular/router';
import { ChildComponentService } from '../_services/child-component.service';
import { ShowContent } from '../_models/show-content.enum';
import { Stand } from '../_models/stand';
import { StandsService } from '../_services/stands.service';
import { AuthenticationService } from '../_services/authentication.service';
import { PlatformLocation, Location, LocationStrategy } from'@angular/common';
import { interval } from 'rxjs';

@Component({
  selector: 'app-stands',
  templateUrl: './stands.component.html',
  styleUrls: ['./stands.component.css'],
  // encapsulation: ViewEncapsulation.Emulated,
})
export class StandsComponent implements OnInit {

  @ViewChild('availableStand') availableStand: MatSelectionList;
  @ViewChild('dene') dene: MatSelectionList;


  standList: Stand[] = [];
  selectedStand: Stand[] = [];
  /**
   * @Output() ermöglicht Daten vom Child an die Parent-Komponente zu senden.
   * nextPageContent der Name des @Output()
   * EventEmitter die Daten vom Typ ShowContent
   */
  @Output() nextPageContent = new EventEmitter<ShowContent>();
  //ausgewählte Stand
  canSelectedStand: number;

  constructor(
    private router: Router,
    private childComponentService: ChildComponentService,
    private categorieservice: StandsService,
    private auth: AuthenticationService,
    private location: PlatformLocation,
    private locationStrategy: LocationStrategy
  ) {
    // location.onPopState(() => {
    //   console.log('basildi')
    //   history.pushState(null, null, window.location.href);
    // })
    this.preventBackButton();
    // history.pushState(null, null, window.location.href);
    // this.location.onPopState(() => {
    //   console.log('++++++++++++')
    //   history.pushState(null, null, window.location.href);
    // })
  }

  preventBackButton() {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    })
  }

  //  @HostListener('window:popstate', ['$event'])
  // onPopState(event: Event) {
  //   // this.router.navigateByUrl('stand')
  //   console.log('tamam')
  //   this.preventBackButton();
  // }

  public deneme: number = 0;
  ngOnInit(): void {
    // interval(2000).subscribe(() => this.getStands())

    // setInterval(() => {
    //   // this.deneme++;
    //   this.getStands();
    //   // console.log('DNEME************ ' + this.deneme);
    // }, 2000)
    //ermittelt letzte ausgwählte Stand
    this.childComponentService.standSubject.subscribe((value: any) => {
      if (value) {
        this.canSelectedStand = value.Nummer;
      }
    });
    this.getStands();

        // this.categorieservice.getCategories().then((data: Stand[]) => {
      
        //   if(data) {

        //     this.standList = data;

        //     const index = this.standList.findIndex(stand_list => stand_list.Nummer === this.canSelectedStand);
        //     if (index === -1) {
        //       this.selectedStand = [this.standList[0]]
        //       this.auth.loginStatus(true);
        //       setTimeout(() => {
        //         this.availableStand.focus();
        //         }, 20);
        //     } else {
        //       this.selectedStand = [this.standList[index]]
        //       this.auth.loginStatus(true);
        //       this.scrollListToItem(this.canSelectedStand);
        //       setTimeout(() => {
        //         this.availableStand.focus();
        //         }, 20);
        //       this.availableStand._keyManager.setActiveItem(index);

        //     }
        //   } else {
        //     // this.auth.loginStatus(false);
        //     this.router.navigateByUrl('/login');
        //   }
        // });
  }

  getStands() {
    this.categorieservice.getCategories().subscribe((data: Stand[]) => {
      
      if(data) {

        this.standList = data;

        const index = this.standList.findIndex(stand_list => stand_list.Nummer === this.canSelectedStand);
        if (index === -1) {
          this.selectedStand = [this.standList[0]]
          this.auth.loginStatus(true);
          setTimeout(() => {
            // this.availableStand.focus();
            this.availableStand._items.get(0)._hostElement.focus();
            }, 20);
        } else {
          this.selectedStand = [this.standList[index]]
          this.auth.loginStatus(true);
          this.scrollListToItem(this.canSelectedStand);
          setTimeout(() => {
            this.availableStand.focus();
            // this.dene.focus();
            this.availableStand._items.get(index)._hostElement.focus();
            // console.log("++++++++++++++++++++++++++++++++++ " + this.availableStand._element.nativeElement)
            }, 20);
            // console.log("keymanager:: " + this.availableStand._element + " ,index: " + index)
            
          // this.availableStand._keyManager.setActiveItem(index);
        }
      } else {
        // this.auth.loginStatus(false);
        this.router.navigateByUrl('/login');
      }
    });
  }
  

  /**
   * Es ermöglicht die Liste zu einer bestimmten Position zu scrollen.
   * @param selectedStand 
   */
  public scrollListToItem(selectedStand: number){

    setTimeout(
      () => {
    // const topPos = this.availableStand.options.find(v => v.value.Nummer === selectedStand)._getHostElement().offsetTop;
    // document.getElementById("container").scrollTop = topPos -160;
    const topPos = this.availableStand.options.find(v => v.value.Nummer === selectedStand)._hostElement.offsetTop;
    document.getElementById("container").scrollTop = topPos -160;
      }
      , 100);
    
 }

  goToTableSelect(standNumber: number, standLanguage: string) {
    // setzt letzte ausgewählte Stand
      this.childComponentService.setStandData(standNumber, standLanguage);
      this.nextPageContent.emit(ShowContent.TABLE);
    }

}
